import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import './style.scss';
import ZoyideClient from './views/zoyrideClient';
import Chauffeur from './views/chauffeur';
import Truck from './views/truck';
import DoShipClient from './views/doShipClient';
import TripDetailView from '../component/TripDetailView';

const Tracking = () => {
    const { trkId } = useParams();
    const firstCharacter = trkId ? trkId[0].toLowerCase() : null;
    const componentMapping = (type) => {
        switch (type) {
            case '0':
                return {
                    Component: TripDetailView, props: {
                        userType: 'driver'
                    }
                }
            default:
                return {
                    Component: TripDetailView, props: {
                        userType: 'client'
                    }
                };
        }
        // '0': {
        //     Component: TripDetailView, props: {
        //         userType: 'client'
        //     }
        // },
        // 'c': {
        //     Component: TripDetailView, props: {
        //         userType: 'driver'
        //} 
        // 't': {
        //     Component: Truck, props: {
        //         userType: 'client'
        //     }
        // },
        // 'd': {
        //     Component: DoShipClient, props: {
        //         userType: 'client'
        //     }
        // },
    };

    // const ComponentToRender = componentMapping[firstCharacter];
    const ComponentToRender = componentMapping(firstCharacter);

    return (
        <div style={{ maxWidth: "450px", margin: "auto" }}>
            {ComponentToRender ? <ComponentToRender.Component {...ComponentToRender.props} /> : <div>No Tracking id found</div>}
        </div>
    );
}

export default Tracking