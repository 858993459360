import React, { useState } from 'react'
import { FaUserGroup } from 'react-icons/fa6'
import CallOptions from '../Call/CallOptions'
import { Button } from 'reactstrap';
import BottomModal from '../BottomModal/BottomModal';
import Call from '../Call/Call';

const PassengersDisplayComponent = ({ tripData, setIsOpen, showCallOptions = false }) => {
    const [selectedPassenger, setSelectedPassenger] = useState(null);

    const handlePassengerClick = (passengerIndex) => {
        setSelectedPassenger(passengerIndex === selectedPassenger ? null : passengerIndex);
    };

    return (
        <>
            {tripData?.passengers?.length ? <div className='px-3 py-2  my-2 font-size-12 shadow-md border-radius-12'>
                <div className='d-flex align-items-center'>
                    <div className='leftIconWidth'><FaUserGroup size={16} /></div>  <b className='font-size-18 ms-1'>Passengers</b>
                </div>
                {tripData?.passengers?.map((passenger, pIndex) => {
                    return (<div key={pIndex} className='d-flex justify-content-between  text-color-grey font-size-18 my-1'>
                        <div className='d-flex align-items-center'>
                            <div className='leftIconWidth'>{pIndex + 1}.</div><div className='ms-1'>{passenger?.name}</div>
                        </div>
                        {showCallOptions && <Call data={passenger} />}
                    </div>)
                })}
            </div> : null}
        </>
    )
}

export default PassengersDisplayComponent