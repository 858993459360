import React from 'react'
import { IoArrowBackOutline } from 'react-icons/io5';
import { Button, Card, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import ThreeDotHelperComponent from './ThreeDotHelperComponent';
import { FaUserNurse, FaUserTimes } from 'react-icons/fa';
import { FaCircleExclamation } from 'react-icons/fa6';
import './style.scss'
import { showMessage } from '../../utils/message';
import { doPUT } from '../../utils/Httputil';
import { TRIP_STATUS } from '../../utils/config';
import { ENDPOINTS } from '../../LiveTracking/LiveTrackingConstant';
const CancelRideMenu = ({ setIsOpen, tripData, updateTripData }) => {

    const cancelRide = async () => {
        try {
            const response = await doPUT(ENDPOINTS.cancelTrip, {
                _id: tripData?._id,
                status: TRIP_STATUS.CANCELLED
            });
            if (response.status == 200) {
                //trip started update trip detail
                updateTripData(response.data);
                setIsOpen(false);
                showMessage("success", "Ride cancelled successfully");
                return;
            }
        } catch (error) {

        }
    }

    return (
        <div className='modal-overlay justify-content-start'>
            <Card className=' shadow cancel-ride-modal mt-auto'>
                <div className='d-flex justify-content-between px-3 py-2'>
                    <div className='cursor-style ' onClick={() => {
                        setIsOpen(false)
                    }}><IoArrowBackOutline size={24} /></div>
                    <div className='font-size-20'>Cancel ride?</div>
                    <div></div>
                </div>
                <hr className='my-1' />
                <div className='px-3 mb-2 font-size-20'>Are you sure you want to cancel your ride?</div>
                {/* 
            <ThreeDotHelperComponent icon={<FaUserNurse size={22} />} content="Driver asked me to cancel" />
            <ThreeDotHelperComponent icon={<FaUserNurse size={22} />} content="Wait time was too long" />
            <ThreeDotHelperComponent icon={<FaCircleExclamation size={22} />} content="Driver asked me to go directly/offline" />
            <ThreeDotHelperComponent icon={<FaUserNurse size={22} />} content="Driver not getting closer" />
            <ThreeDotHelperComponent icon={<FaUserTimes size={22} />} content="Could not find driver" />
            <ThreeDotHelperComponent icon={<FaUserNurse size={22} />} content="Driver arrived early" />
            <ThreeDotHelperComponent icon={<FaUserNurse size={22} />} content="other" /> */}

                <div className='d-flex gap-2 mx-2 mb-3'>
                    <Button className='w-100   bg-purple font-size-22' onClick={() => setIsOpen(false)}>No</Button>
                    <Button className='w-100   font-size-20' onClick={cancelRide}>Yes</Button>
                </div>
            </Card>
        </div>
    )
}

export default CancelRideMenu