import React, { useContext, useEffect, useMemo, useState } from 'react'
import Layout from '../../component/TripDetailView/Layout'
import { useLocation } from 'react-router-dom'
import { UserContext } from '../../service/context/userContext';
import SpaceDetail from '../../component/TripDetailView/SpaceDetail';
import TimeLine from '../../component/TripDetailView/Cards/Timeline';
import TrackingCardHeader from '../../component/TripDetailView/Cards/TrackingCardHeader';
import DetailHotel from '../../component/TripDetailView/Cards/DetailHotel';
import TripDetails from '../../component/TripDetailView/TripDetails';
import FlightDetail from '../../component/TripDetailView/FlightDetail';
import StayDetail from '../../component/TripDetailView/StayDetail';
import { getFlightStatus, getStayStatus } from '../../utils/config';
import CenteredModal from '../../component/CenteredModal';
import Loader from '../../component/Loader';

function LandingScreen() {
    const {tourData,error,loading}=useContext(UserContext);
    const [modal,setModal]=useState({
        visible:false,
        type:null,
        data:null
    })

    const onToggle=()=>setModal({visible:false,type:null,data:null})
    const showModal=(type,data)=>{
        setModal({visible:true,type,data})
        window.scrollTo(0, 0);

    }

 

    return (
        <>
        {loading?
        <Loader />
        :<div className='overflow-hidden mh-100'>
        <Layout>
           {error?null: <SpaceDetail space={tourData?.space}/>}
            <div className='px-2 bg-white'>
            {
                tourData?.flights?.map((flight,index)=>(
                    <div key={index} className='shadow-box mt-3' onClick={()=>showModal( "flight",flight)}>
                    <TrackingCardHeader title={flight?.airline} type="flights" status={getFlightStatus(flight?.status)}/>
                    <TimeLine 
                       fromCity={flight?.fromCity}
                       toCity={flight?.toCity}
                       fromDate={flight?.date}
                       fromTime={flight?.time}
                       toDate={flight?.landingDate}
                       toTime={flight?.landingTime}
                    />
                 </div>
                ))
            }
            {
                tourData?.stays?.map((stay,index)=>(
                    <div key={index} className='shadow-box mt-3' onClick={()=>showModal( "stay",stay)}>
                    <TrackingCardHeader title={stay?.hotelName} type="stays" status={getStayStatus(stay?.status)} />
                    <DetailHotel
                       bookingDate={stay?.bookingDate}
                       checkInDate={stay?.checkInDate}
                       checkInTime={stay?.checkInTime}
                       checkOutDate={stay?.checkOutDate}
                       checkOutTime={stay?.checkOutTime}
                       address={stay?.address??""}
                       contact={stay?.contactDetail??""}
                    />
                 </div>
                ))
            } 
             </div>   
            {modal?.visible?<div className='position-absolute bg-white vh-100 px-2' style={{bottom:0,right:0,top:0,left:0}}>
              {modal?.type==="flight"?<FlightDetail onToggle={onToggle} data={modal?.data}/>:<StayDetail onToggle={onToggle} data={modal?.data}/>}
            </div>:null}
        </Layout>
        </div>}
        <CenteredModal visible={error} title={'Invalid Tracking URL'} closeAble={false} >
                <div style={{ width: 320 }}>
                    <p className='text-center'>The URL you have entered is invalid or cannot be tracked. Please check and try again.</p>
                </div>
            </CenteredModal>
        </>
    )
}

export default LandingScreen