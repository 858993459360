import React from 'react'
import './style.scss'

function CenteredModal({ children, title, visible, onClose, background = '#FFF', closeAble = true }) {
    return (
        <>
            {visible ? <div className='modal-overlay' onClick={onClose}>
                <div className="centered-modal-content" onClick={(e) => e.stopPropagation()} style={{ background }}>
                    {closeAble ? <span onClick={onClose} className="close">&times;</span> : null}
                    <p className='title me-3'>{title}</p>
                    {children}
                </div>
            </div> : null}
        </>
    )
}

export default CenteredModal