import React from 'react'
import { IoArrowBackOutline } from 'react-icons/io5'
import { RxCross2 } from 'react-icons/rx'
import { Button, Card } from 'reactstrap'

const CallAdmin = ({ setIsOpen }) => {
    return (
        <>
            <div className='d-flex justify-content-between px-3 py-2'>
                <div className='font-size-24'>Call </div>
                <div></div>
                <div className='cursor-style' onClick={() => {
                    setIsOpen(false)
                }}><RxCross2 size={28} /></div>
            </div>

            <Button className='m-2 mx-3 bg-purple font-size-24'>Call admin</Button>
        </>
    )
}

export default CallAdmin