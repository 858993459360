import React, { useCallback, useEffect, useState } from 'react';
import LocationAccess from '../../component/LocationAccess';
import { useLocation } from 'react-router-dom';
import { doGET, doPUT } from '../../utils/Httputil';
import { ENDPOINTS } from '../../LiveTracking/LiveTrackingConstant';
import { showMessage } from '../../utils/message';
import OpenStreetMap from '../../component/OpenStreetMap';
import { Button } from 'reactstrap';
import AddressField from '../../component/AddressField';


const AddressSelect = () => {
    const location = useLocation();
    const [loc, setLocation] = useState(null);
    const [customer, setCustomer] = useState(null)

    const fetchCustomerDetail = async (customer_id) => {
        const response = await doGET(ENDPOINTS.getCustomerDetail(customer_id));
        setCustomer(response.data)
        if (response?.data?.residence?.lat && response?.data?.residence?.lng) {
            setLocation({ lat: response?.data?.residence?.lat, lng: response?.data?.residence?.lng })
        }
    }

    const fetchToken = async (customer_id) => {
        try {
            //TODO check this only is correct or not
            if (localStorage.getItem('BearerToken')) {
                localStorage.removeItem('BearerToken')
            }
            const response = await doGET(ENDPOINTS.customerSetHome(customer_id));
            console.log(response);
            const { bearerToken } = response.data;
            if (bearerToken) {
                localStorage.setItem("BearerToken", bearerToken);
                await fetchCustomerDetail(customer_id);
            }
        } catch (error) {
            console.log(error);
            let message = "Something went wrong"
            if (error?.message) {
                message = JSON.parse(error.message).message
            }
            showMessage("error", message);
        }
    }

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        if (queryParams.get('c')) {
            fetchToken(queryParams.get('c'));
        }
    }, [location.search]);

    const onMarkerDragged = useCallback((center) => {
        setLocation(center)
    }, [setLocation])

    const updateLocation = async () => {
        // TODO call api to get address fulll address from api
        try {
            const response = await doPUT(ENDPOINTS.updateCustomer, {
                ...customer,
                residence: { ...customer?.residence, lat: loc?.lat, lng: loc?.lng }
            });
            fetchCustomerDetail(response?.data?._id)
            showMessage("success", "Location updated successfully");
        } catch (error) {
            let message = "Something went wrong"
            if (error?.message) {
                message = JSON.parse(error.message).message
            }
            showMessage("error", message);
        }
    }


    return (
        <div className=' vh-100  w-100 position-relative' style={{ maxWidth: "450px", margin: "auto" }}>
            <>
                {!customer ? <div className='bg-white  w-100 my-2' style={{ zIndex: 999999 ,height:80 }}>
                    <div className='text-center fw-bold'>
                        Update Residence
                    </div>
                    <AddressField
                        placeholder="Enter Home address"
                        label="Residence"
                        value={customer?.residence}
                        outline={true}
                        className={"w-100 mt-2"}
                        onChange={(e) => {
                            setCustomer(cus => ({ ...cus, residence: e }))
                            if (e?.lat && e?.lng) {
                                setLocation({ lat: e?.lat, lng: e?.lng })
                            }
                        }}
                    />
                </div> : null}
                <div className='d-flex flex-1 w-100 ' style={{height:'calc(100% - 100px)'}}>
                    <OpenStreetMap markers={[[]]} polyLine={""} center={loc} onMarkerDragged={onMarkerDragged} showLocationPicker />
                </div>
                <div className='bg-white position-absolute w-100 px-2' style={{ zIndex: 999,bottom:10 }}>
                    <Button disabled={!location} className='w-100 my-2 font-size-20' onClick={updateLocation}>Update Location</Button>
                </div>
            </>
            <LocationAccess />
        </div>
    );
}

export default AddressSelect