import React from 'react'
import './style.scss'

const BottomModal = ({ children }) => {
    return (
        <div className='w-100 h-100 threeDotStyle' >
            {children}
        </div>
    )
}
export default BottomModal