import React, { useEffect, useState } from 'react'
import LocationInfo from '../LocationInfo'
import LocationService from '../../service/LocationService';

function LocationAccess() {
    const [showLocationInfo, toggleModal] = React.useState(false);
    const close = () => toggleModal(false);
    const [loading,setLoading]=useState(false)

    useEffect(() => {
        toggleModal(true)
    }, [])

    const handleLocationInfoClose = async (accepted = false) => {
        try {
            if (accepted) {
                await LocationService.fetchLocation()
            } else {
                close()
            }
        } catch (error) {

        } finally {
            close();
        }

    }

  return (
    <>
      {showLocationInfo && <LocationInfo close={handleLocationInfoClose} />}
    </>
  )
}

export default LocationAccess