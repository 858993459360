import moment from 'moment'
import React from 'react'

const getAddressLine=(address)=>{
  return  [
    address?.addressLine,
    address?.city,
    address?.state,
    address?.country
].filter(Boolean).join(', ');
}

function SpaceDetail({space}) {
  return (
    <div className='urbanist-600'  style={{ backgroundColor: '#4954e0', color: '#fff' }}>
    <h1 id="headerHeading">{space?.name}</h1>
    <div className='d-flex'  >
        <div className=' w-75 px-2'>
            <h3 className='break-word urbanist-600' style={{ fontSize: 18 }}>Address</h3>
            <p className='break-word urbanist-600'>{getAddressLine(space?.eventLocation)}</p>
        </div>
        <div className=' w-25'>
            <h3 className='break-word urbanist-600' style={{ fontSize: 18 }}>Date</h3>
            <p className='break-word urbanist-600'>{moment(space?.fromDate,'YYYYMMDD').format("MMM Do YYYY")}</p>
            {/* <p>{moment.unix(space?.fromTime).format("hh:mm A")} Onwards</p> */}
        </div>
    </div>
</div >
  )
}

export default SpaceDetail