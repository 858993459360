import React, { useState } from 'react'
import CallOptions from './CallOptions'
import { Button } from 'reactstrap'
import BottomModal from '../BottomModal/BottomModal'

const Call = ({ data }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const onClick = () => setIsModalOpen(true);

    return (
        <div>
            <Button onClick={onClick}>Call</Button>
            {
                isModalOpen ? (<BottomModal>
                    <CallOptions data={data} setIsOpen={setIsModalOpen} />
                </BottomModal>) : null
            }
        </div>
    )
}

export default Call