import React, { useContext, useEffect, useState, useRef, useCallback } from "react";
import { observer } from "mobx-react-lite";
import { toJS } from "mobx";
import Select, { components } from "react-select";
import debounce from 'lodash/debounce';
import AutoFillService from "../../service/AutoFillService";
import { showMessage } from "../../utils/message";


const CityCountrySelect = ({ asLabel, style, onChange, value, noLabel }) => {
    const [cities, setCities] = useState([]);
    const [fetchError, setFetchError] = useState(false); // New state to track fetch errors
 
    const [selectedCity, setSelectedCity] = useState(null);
    const [inputValue, setInputValue] = useState("");
    const selectRef = useRef(null);  

    const updateCities = useCallback(() => {
        setCities(
            toJS(AutoFillService?.cities)?.map((city) => ({
                ...city,
                value: city?.name,
                label: (
                    <div>
                        {city?.name},
                        <span style={{ color: "#707070", fontSize: "11px" }}>
                            {" " + city?.state},
                            {" " + city?.country}
                        </span>
                    </div>
                ),
            }))
        );
    }, []);
    console.log(("====",cities));

    useEffect(() => {
        if (AutoFillService.cities.length === 0) {
            AutoFillService.fetch().then(updateCities).catch(error => console.log("Error", error));
        }
    }, [updateCities]);

    useEffect(() => {
        if (value && cities.length) {
            const city = cities.find(c => c.name === value.city && c.state === value.state && c.country === value.country);
            if (city) setSelectedCity({...city, city : city?.name});
        }
    }, [value, cities]);

    useEffect(() => {
        updateCities();
    }, [AutoFillService.cities, updateCities]);

    const fetchNewCity = useCallback(debounce(async () => {
        if (inputValue.trim()) {
            try {
              const response =   await AutoFillService.searchNAddCity(inputValue, "India");
              if(response?.status == 400){
                  setFetchError(true);
              } else {
                  setFetchError(false);
                  updateCities();
              }
      
            } catch (error) {
                console.log(error)
                setFetchError(true); 
                let message = "Error fetching new city"
                if (error?.message) {
                    message = JSON.parse(error.message).message
                }
                showMessage("error", message); 
                selectRef.current?.select?.focus();
            }
        }
    }, 500), [updateCities, inputValue,   showMessage]);

    const noOptionsMessage = useCallback(() => {
        if (fetchError) {
            return "City not found";
        }
        fetchNewCity();
        return inputValue.trim() ? ("Loading city with name ") + inputValue : "Start typing to search for a city...";
    }, [fetchNewCity,  fetchError, inputValue]);

    const onInputChange = useCallback((inputValue, { action }) => {
        if (action === 'input-change') {
            setFetchError(false)
            setInputValue(inputValue);
        }
    }, []);

    return (
        <>
            {asLabel ? (
                <div>{selectedCity?.label ?? <div style={{ color: "grey" }}>{"No record Found"}</div>}</div>
            ) : (
                <Select
                    ref={selectRef}
                    className="cityCountrySelect"
                    isDisabled={asLabel || cities.length === 0}
                    components={{ Input: components.Input, DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                    autoComplete="new-password"
                    options={cities}
                    styles={{ style }}
                    value={selectedCity}
                    noLabel={noLabel}
                    placeholder={"Select City"}
                    onChange={v => {
                        if (v?.name !== value?.city || v?.state !== value?.state || v?.country !== value?.country) {
                            onChange(v);
                        }
                    }}
                    menuPlacement="auto"
                    noOptionsMessage={noOptionsMessage}
                    onInputChange={onInputChange}
                />
            )}
        </>
    );
};

export default observer(CityCountrySelect);
