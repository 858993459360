import React from 'react';
import Layout from './Layout';
import ArrowLeft from "./../../assets/svgs-icons/ArrowLeft";
import "./styles.css";
import TrackingTrackingCardHeader from './Cards/TrackingCardHeader';
import TimeLine from './Cards/Timeline';
import Heading from './Cards/Head';
import moment from 'moment';
import Hotel from '../../assets/svgs-icons/Hotel';
import { FaPhoneAlt } from 'react-icons/fa';




const StayDetail = ({ onToggle, data }) => {
    return (
        <Layout>
            <div className='d-flex align-items-center mt-3 p-2' style={{ cursor: 'pointer' }} onClick={onToggle}>
                <ArrowLeft />
                <p className='p-0 m-0 fw-semibold ps-2'>Stay Details</p>
            </div>

            <div className='shadow-box mt-3 py-2'>
                <div className='d-flex align-items-center'>
                    <Hotel />
                    <div className='d-flex align-items-center justify-content-between flex-grow-1 w-100'>
                        <div className='w-75 text-ellipsis overflw-hidden'>
                            <div className='ms-2 urbanist-600'>{data?.hotelName}</div>
                        </div>
                        <div className='w-25'>
                            <div className='d-flex justify-content-center align-items-center bg-light custom-padding rounded-3 text-success urbanist-600 py-2' style={{ fontSize: 12 }}>Confirmed</div>
                            
                        </div>
                    </div>
                </div>
                <div className='p-0 m-0 ps-2 text-muted  urbanist-600 mt-2 break-word'>{data?.address}</div>
                {data?.contactDetail?<div className='text-muted  urbanist-500 w-100 d-flex justify-content-end pe-1 mt-2 align-items-center'>
                    <FaPhoneAlt className='me-1' />
                    {data?.contactDetail ?? ""}
                </div>:null}


                <div className='d-flex p-1 pt-3 mt-2 align-items-start' style={{ borderTop: '1px solid #C4C4C4', flex: 1 }}>
                    <div className=' '>
                        <p className='me-3  text-success urbanist-600'>Check In</p>
                        <p className='p-0 m-0 urbanist-500  text-muted'>{moment(data?.checkInDate, 'YYYYMMDD').format("DD MMM YYYY")} </p>
                        <p className='p-0 m-0 urbanist-500  text-muted'>{moment(data?.checkInTime, 'HHmm').format("HH:mm")} </p>
                    </div>
                    <div style={{ flex: 1 }}>
                        <div style={{ width: '100%' }} className="mt-3  d-flex justify-content-center align-items-center position-relative">
                            <div className="line bg-primary flex-grow-1"></div>
                        </div>
                    </div>
                    <div className='d-flex flex-column align-items-end'>
                        <p className='ms-3 text-danger  urbanist-600 '>Check Out</p>
                        <p className='p-0 m-0  urbanist-500 text-muted'>{moment(data?.checkOutDate, 'YYYYMMDD').format("DD MMM YYYY")} </p>
                        <p className='p-0 m-0  urbanist-500 text-muted'>{moment(data?.checkOutTime, 'HHmm').format("HH:mm")} </p>
                    </div>
                </div>

            </div>
        </Layout>
    )
}

export default StayDetail