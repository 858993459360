import React from 'react'
import { Button } from 'reactstrap';

function LocationInfo({ close }) {
    return (
        <div style={{zIndex:999}} className='d-flex flex-column position-absolute bottom-0 bg-white shadow-lg p-3'>
            <p className='text-center fw-bold'>Location Access Required</p>
            <p>
                To use this app, you need to allow the app to access your location.
                To provide you with the best experience, we need to access your location.
                This will enable us to offer personalized services. Would you like to share your location?
            </p>

            <div className='d-flex gap-2'>
                <Button outline className='w-100 my-2 font-size-20' onClick={() => close(false)}>
                    No
                </Button>
                <Button className='w-100 my-2 font-size-20' onClick={() => close(true)}>
                    Yes
                </Button>
            </div>
        </div>
    )
}

export default LocationInfo