import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import '../../LiveTracking/style.scss'
import { observer } from 'mobx-react-lite'
import { doGET } from '../../utils/Httputil';
import LiveTrackingService from '../../LiveTracking/LiveTrackingService';
import CenteredModal from '../CenteredModal';
import { CircleIcon, SquareIcon } from '../Map/Icons';
import TripTrackingView from './TripTrackingView';
import { TRIP_STATUS } from '../../utils/config';
import socketInstance, { SOCKET_EVENT } from '../../service/socket';
import TripList from './TripList';
import { CarIcon } from '../Map/Icons';

const TripDetailView = ({ userType }) => {
    const { trkId } = useParams();
    const [tripData, setTripData] = useState(null); //{trip,otp,markers,polyLine:""}
    const [error, setError] = useState(false);

    const fetchData = async () => {
        try {
            if (!trkId) return setError(true);
            setError(false);
            localStorage.clear();
            const trackingResponse = await doGET(`/api/track?code=${trkId}`);
            if (trackingResponse.status === 200 && trackingResponse.data) {
                const { trip, otp = "", bearerToken } = trackingResponse.data;
                const markers = [];
                if (trip?.src?.lat && trip?.src?.lng) {
                    markers.push({ lat: trip?.src?.lat, lng: trip?.src?.lng, icon: CircleIcon, type: 'src' });
                }
                if (trip?.dst?.lat && trip?.dst?.lng) {
                    markers.push({ lat: trip?.dst?.lat, lng: trip?.dst?.lng, icon: SquareIcon, type: 'dst' });
                }

                setTripData({ ...trip, otp, markers });
                fetchVehicleData(trip);
                if (bearerToken) {
                    localStorage.setItem("BearerToken", bearerToken)
                }
                if([TRIP_STATUS.LIVE,TRIP_STATUS.DISPATCHED].includes(trip?.status)){
                    updatePolyLine(trip?._id);
                }
                else if (trip?.src?.lat && trip?.src?.lng && trip?.dst?.lat && trip?.dst?.lng) {
                    const polyLine = await LiveTrackingService.fetchPath(trip?.src?.lat, trip?.src?.lng, trip?.dst?.lat, trip?.dst?.lng)
                    if (polyLine) {
                        setTripData(prev => ({ ...prev, polyLine }));
                    }
                }
            }
        } catch (error) {
            console.error('Error fetching tracking data:', error);
            setError(true);
        }
    };



    const updatePolyLine = async (tripId) => {
        try {
            const data = {
                trip_id: tripId
            }
            const locate = await LiveTrackingService.locateVehicle(data);
            if(locate?.path){
                setTripData(prev => ({ 
                    ...prev,
                     polyLine:locate?.path,
                     markers:[...(prev.markers??[]),{ lat: locate?.current?.lat, lng: locate?.current?.lng, icon: SquareIcon, type: 'driver' }]
                     }));
            }
        } catch (error) {

        }
    }



    const fetchVehicleData = async (trip) => {
        try {
            let vehicleData = await LiveTrackingService.fetchVehicleData(trip);
            if (vehicleData) {
                setTripData(prevData => ({ ...prevData, vehicleData }));
            }
        } catch (error) {
            console.log(error);
        }
    }

    const setUpSocketInteractions = async (trip_id) => {
        try {
            let socket = socketInstance.getSocket();

            if (!socket) {
                socket = await socketInstance.initializeSocket(trkId);
            }

            if (LiveTrackingService?.socketTripId) {
                socket?.emit('leaveRoom', 'trip-' + LiveTrackingService?.socketTripId, (response) => {
                    console.log("room leave response:", response);
                    LiveTrackingService?.setSocketTripId(null)
                });
            }

            socket?.emit('joinRoom', 'trip-' + trip_id, (response) => {
                LiveTrackingService?.setSocketTripId(trip_id)
                console.log("trip room join response:", response);
            });

            // socketInstance?.off(SOCKET_EVENT.LOCATION_UPDATE)
            socket?.on(SOCKET_EVENT.LOCATION_UPDATE, (data) => {

                console.log("location update:", data)
                if (!data) {
                    return;
                }

                if (data.lat && data.lng) {
                    console.log("location update:", data)
                    let prevMarkers = tripData?.markers ?? [];
                    let vehicleMarkerIndex = prevMarkers.findIndex(marker => marker.type === 'vehicle');
                    if (vehicleMarkerIndex != -1) {
                        const vehicleMarker = prevMarkers[vehicleMarkerIndex];
                        prevMarkers[vehicleMarkerIndex] = { ...vehicleMarker, lat: data.lat, lng: data.lng, icon: CarIcon, type: 'vehicle' }
                    } else {
                        prevMarkers.push({ lat: data.lat, lng: data.lng, icon: CarIcon, type: 'vehicle' })
                    }
                    setTripData(prev => ({ ...prev, markers: prevMarkers }))
                    updatePolyLine(tripData?._id)
                }
            })

            socket?.on(SOCKET_EVENT.TRIP_STOP, (data) => {
                //TODO update location of driver
                console.log("trip stop :", data);
                if (!data) {
                    return;
                }
                // navigation.navigate(NavName.bookings) 
            })
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        fetchData();
    }, [trkId])

    useEffect(() => {
        if (tripData?._id) {
            setUpSocketInteractions(tripData?._id);
        }
    }, [tripData?._id])

    return (
        <div>
            <TripTrackingView tripData={tripData} setTripData={setTripData} trkId={trkId} userType={userType} />
            <CenteredModal visible={error} title={'Invalid Tracking URL'} closeAble={false} >
                <div style={{ width: 320 }}>
                    <p className='text-center'>The URL you have entered is invalid or cannot be tracked. Please check and try again.</p>
                </div>
            </CenteredModal>
        </div >
    )
}

export default observer(TripDetailView)