import React from 'react';
import Layout from './Layout';
import ArrowLeft from "./../../assets/svgs-icons/ArrowLeft";
import "./styles.css";
import TrackingCardHeader from './Cards/TrackingCardHeader';
import TimeLine from './Cards/Timeline';
import SingleDetail from './Cards/SingleDetail';
import Heading from './Cards/Head';
import TakeOff from '../../assets/svgs-icons/TakeOff';
import Pnr from '../../assets/svgs-icons/Pnr';
import Landing from '../../assets/svgs-icons/Landing';
import Clock from '../../assets/svgs-icons/Clock';
import Person from '../../assets/svgs-icons/Person';
import moment from 'moment';
import { calculateDuration } from '../../helper/helper';
import { getFlightStatus } from '../../utils/config';

const ammenities = [
    { id: 0, title: "Cabin Bag 1 x 7 kgs" },
    { id: 1, title: "Check-in Luggage 1x 15 kgs" },
    { id: 2, title: "Reschedule Available" },
    { id: 3, title: "Refundable" },
    { id: 4, title: "Travel Insurance Included" },
]




const FlightDetail = ({ onToggle, data }) => {
 
    return (
        <div className="vh-100  h-100" style={{minHeight:'100vh',overflow:'auto'}}>
            <div className='d-flex align-items-center mt-3 p-2' style={{ cursor: 'pointer' }} onClick={onToggle}>
                <ArrowLeft />
                <p className='p-0 m-0 fw-semibold ps-2'>Trip Details</p>
            </div>

            <div className='shadow-box mt-3'>
                <TrackingCardHeader title={data?.airline} type="flights" status={getFlightStatus(data?.status)} />
                <TimeLine
                    fromCity={data?.fromCity}
                    toCity={data?.toCity}
                    fromDate={data?.date}
                       fromTime={data?.time}
                       toDate={data?.landingDate}
                       toTime={data?.landingTime}
                />

                <div className='pt-2'>
                    <div className='d-flex p-1'>

                        <div className='d-flex p-1' style={{ flex: 1 }}>
                            <TakeOff />
                            <div className='ps-2'>
                                <p className='p-0 m-0 urbanist-600'>Take-off</p>
                                <p className='p-0 m-0 urbanist-600'>{data?.date?moment(data?.date,'YYYYMMDD').format('DD MMM YYYY'):'-'}</p>
                            </div>
                        </div>
                        <div className='d-flex p-1' style={{ flex: 1 }}>
                            <Pnr />
                            <div className='ps-2'>
                                <p className='p-0 m-0 urbanist-600'>PNR No.</p>
                                <p className='p-0 m-0 urbanist-600'>{data?.PNR}</p>
                            </div>
                        </div>
                    </div>
                    <div className='d-flex p-1'>
                        <div className='d-flex p-1' style={{ flex: 1 }}>
                            <Landing/>
                            <div className='ps-2'>
                                <p className='p-0 m-0 urbanist-600'>Landing</p>
                                <p className='p-0 m-0 urbanist-600'>{data?.landingDate?moment(data?.landingDate,'YYYYMMDD').format('DD MMM YYYY'):'-'}</p>
                            </div>
                        </div>
                        <div className='d-flex p-1' style={{ flex: 1 }}>
                            <Person />
                            <div className='ps-2'>
                                <p className='p-0 m-0 urbanist-600'>Passenger Name</p>
                                <p className='p-0 m-0 urbanist-600'>{data?.passengers?.[0]?.salutation ?? ""} {data?.passengers?.[0]?.name}</p>
                            </div>
                        </div>
                        
                    </div>
                    <div className='d-flex p-1'>
                    {(!data?.date|| !data?.time|| !data?.landingDate|| !data?.landingTime)?null:<div className='d-flex p-1' style={{ flex: 1 }}>
                            <Clock />
                            <div className='ps-2'>
                                <p className='p-0 m-0 urbanist-600'>Flight Duration</p>
                                <p className='p-0 m-0 urbanist-600'>{calculateDuration(data?.date, data?.time, data?.landingDate, data?.landingTime)}</p>
                            </div>
                        </div>}
                    </div>
                </div>

            </div>

            {/* Amenities */}
            {/* <div className='shadow-box mt-3'>
                <Heading title={"Flight Amenitites"} />
                <div className='mx-2'>
                    {
                        ammenities.map(e => {
                            return <div key={e.id} style={{ fontSize: 13 }} className='mt-2'>{e.title}</div>
                        })
                    }
                </div>
            </div> */}

            {/* Contact */}
            <div className='shadow-box mt-3'>
                <Heading title={"Passenger Details"} />
                {data?.passengers?.map((passenger, index) => (
                    <div className='m-2' key={index}>
                        <h1 className='fs-5 text-muted urbanist-600'>{passenger?.salutation ?? ""} {passenger?.name}</h1>
                        <div className='d-flex align-items-center justify-content-between'>
                            <p className='fs-6 text-muted p-0 m-0 urbanist-600'>{passenger?.email ?? ""}</p>
                            <p className='fs-6 text-muted p-0 m-0 urbanist-600'>{passenger?.phone?.countryCode ?? ""} {passenger?.phone?.phone ?? ""}</p>
                        </div>
                    </div>

                ))}
            </div>

            {/* Passsenger */}
            {/* <div className='shadow-box mt-3'>
                <Heading title={"Passenger Details"} />
                <div className='table-responsive'>
                    <table className="ms-2 table table-striped" style={{ fontSize: 12 }}>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Age</th>
                                <th>Gender</th>
                                <th>Booking Status</th>
                                <th>Current Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Shweta Singh</td>
                                <td>30</td>
                                <td>Female</td>
                                <td>CNF/HA1/LOWER</td>
                                <td>CNF/HA1/LOWER</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div> */}

        </div>
    )
}

export default FlightDetail