
export const ENDPOINTS = {
    getVehicleType: (id) => `/api/vehicle-type/${id}/detail`,
    getTrip: (id) => `/api/trip/${id}/detail`,
    makeModel: (id) => `/api/make-model/${id}/detail`,
    startTrip: '/api/trip/start',
    stopTrip: '/api/trip/stop',
    cancelTrip: "/api/trip/status/modify",
    movements: '/api/movement/locate',
    vehicleDetail: (id) => `/api/vehicle-type/${id}/detail`,
    tourDetail: (space_id,customer_id) => `/api/track?s=${space_id}&c=${customer_id}`,
    getWebContent: '/api/web-content',
    customerSetHome:(customer_id)=> `/api/customer/request-set-home?c=${customer_id}`,
    getCustomerDetail:(customer_id)=> `/api/customer/${customer_id}/detail`,
    updateCustomer:`/api/customer/update`,

};