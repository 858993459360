import React from 'react'
import { FaCarAlt } from "react-icons/fa";
import TrackingCardHeader from '../Cards/TrackingCardHeader';
import DetailVehicle from '../Cards/DetailVehicle';


function TrackingVehicleCard() {
  return (
    <>
      <TrackingCardHeader >
        <>
        <FaCarAlt size={20} />
          <div className='ms-2 urbanist-600'>Airport Pickup <span>6E -0558</span></div>
        </>
      </TrackingCardHeader>
      <DetailVehicle />
    </>
  )
}

export default TrackingVehicleCard