import React from 'react'

const Person = () => {
    return (
        <svg width="12" height="17" viewBox="0 0 12 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 16.5C0 13.1863 2.68629 10.5 6 10.5C9.31373 10.5 12 13.1863 12 16.5H10.5C10.5 14.0147 8.48528 12 6 12C3.51472 12 1.5 14.0147 1.5 16.5H0ZM6 9.75C3.51375 9.75 1.5 7.73625 1.5 5.25C1.5 2.76375 3.51375 0.75 6 0.75C8.48625 0.75 10.5 2.76375 10.5 5.25C10.5 7.73625 8.48625 9.75 6 9.75ZM6 8.25C7.6575 8.25 9 6.9075 9 5.25C9 3.5925 7.6575 2.25 6 2.25C4.3425 2.25 3 3.5925 3 5.25C3 6.9075 4.3425 8.25 6 8.25Z" fill="black" />
        </svg>
    )
}

export default Person;