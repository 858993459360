import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './style.scss'
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
  BrowserRouter,
  Route,
  Routes
} from "react-router-dom";
import { UserProvider } from './service/context/userContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
  <UserProvider>
    <Routes>
      <Route path="/*" element={<App />} />
    </Routes>
  </UserProvider>
  </BrowserRouter>
);
reportWebVitals();
