import React, { useEffect, useState } from 'react';
import '../../LiveTracking/style.scss'
import { GoPackage } from "react-icons/go";
import { Button } from 'reactstrap';
import { TbSteeringWheel } from "react-icons/tb";
import VehicleInfo from '../VehicleInfo';
import socketInstance, { SOCKET_EVENT } from '../../service/socket';
import PassengersDisplayComponent from '../Passengers/PassengersDisplayComponent';
import AddressDisplayComponent from '../AddressField/AddressDisplayComponent';
import ClientMenuModal from '../BottomModal/ClientMenuModal';
import CancelRideMenu from '../BottomModal/CancelRideMenu';
import BottomModal from '../BottomModal/BottomModal';
import LiveTrackingService from '../../LiveTracking/LiveTrackingService';
import { USERTYPE, isTripEnded, isTripReadyToStart, isTripRunning } from '../../utils/config';
import CenteredModal from '../CenteredModal';
import TripStartStop from '../TripStartStop';
import OpenStreetMap from '../OpenStreetMap';
import { CarIcon } from '../Map/Icons';
import BottomSheetView from '../BottomSheetView';
import { getTripType } from '../../helper/helper';

const TripTrackingView = (props) => {
    const { setTripData, tripData, trkId, userType } = props;
    const [isExpand, setIsExpand] = useState(true);
    const [isThreeDotModalOpen, setIsThreeDotModalOpen] = useState(false);
    const [cancelRide, setCancelRide] = useState(false);
    const [showDriverStartStopModal, setShowDriverStartStopModal] = useState(false);
    const updateTripData = (data) => setTripData(data)



    const handleUserRideClick = () => {
        setCancelRide(true);
    }

    const handleDriverClick = () => {
        if (isTripReadyToStart(tripData?.status) || isTripRunning(tripData?.status)) {
            setShowDriverStartStopModal(prev => !prev)
        }
    };

    const getDriverAction = (status) => {
        if (isTripReadyToStart(status)) {
            return { text: "Start Trip", action: "start" };
        } else if (isTripRunning(status)) {
            return { text: "Stop Trip", action: "stop" };
        } else {
            return { text: "Trip Ended", action: null }
        }
    }

    const getUserActionValue = (status) => {
        if (isTripEnded(status)) {
            return { text: "Trip ended", disabled: true };
        }
        if (isTripReadyToStart(status)) {
            return { text: "Cancel ride", disabled: false };
        } else if (isTripRunning(status)) {
            return { text: "Cancel ride", disabled: false };
        }

        return { text: "Cancel ride", disabled: true };
    }

    const updatePolyLine = async (tripId) => {
        try {
            const data = {
                trip_id: tripId
            }
            const polyLine = await LiveTrackingService.locateVehicle(data)
            if (polyLine) {
                setTripData(prev => ({ ...prev, polyLine }));
            }
        } catch (error) {

        }
    }
    return (
        <>
            <div className='w-100 vh-100  d-flex flex-column  justify-content-between position-relative'>
                {/* <MapComponent
                    mapContainerStyle={{
                        height: "100%"
                    }}
                    clustering
                    selectedMapMarker={toJS(LiveTrackingService?.selectedMarker)}
                    centerLatLng={toJS(LiveTrackingService?.center) ?? {}}
                    polyLinePath={toJS(LiveTrackingService?.path) ?? []}
                    markers={toJS(LiveTrackingService?.markers)}
                /> */}
                <OpenStreetMap markers={tripData?.markers ?? [[]]} polyLine={tripData?.polyLine ?? ""} />

                <BottomSheetView className='d-flex flex-column justify-content-between'>
                    <div className='mt-3'>
                        <VehicleInfo tripData={tripData} />
                        {isExpand && <div className='px-3 text-color-grey py-2 my-2 shadow-md border-radius-12'>
                            <div className='d-flex justify-content-between'>
                                <div className='d-flex align-items-center '>
                                    <div className='leftIconWidth'><GoPackage size={20} /></div>
                                    <div className='ms-2 font-size-20'>Package</div>
                                </div>
                                <b className='font-size-20'>{getTripType(tripData?.tripType)}</b>
                            </div>
                        </div>}
                        {isExpand && <AddressDisplayComponent tripData={tripData} />}
                        {isExpand && userType === USERTYPE.DRIVER && <PassengersDisplayComponent tripData={tripData} />}

                        {isExpand && userType === USERTYPE.CLIENT && tripData?.driver ? <div className='px-3 py-2  my-2 font-size-12 shadow-md border-radius-12'>
                            <div className='d-flex justify-content-between'>
                                <div className='d-flex align-items-center'>
                                    <div className='leftIconWidth'><TbSteeringWheel size={18} /></div>  <b className='font-size-18 ms-1'>Driver</b>
                                </div>
                                {/* <b className='font-size-18'>Driver</b> */}
                                {/* <div className='d-flex align-items-centere'>
                                        <Call data={tripData} />
                                    </div> */}
                            </div>
                            <div className='text-color-grey d-flex justify-content-between'>
                                <div className='d-flex font-size-18'>
                                    <div className='leftIconWidth'>1. </div>
                                    <div className='ms-1'>{tripData?.driver?.name ?? ""}</div>
                                </div>
                                <div className='font-size-18'>{tripData?.driver?.phone?.countryCode ? `+${tripData?.driver?.phone?.countryCode}` : ""}{tripData?.driver?.phone?.phone ?? ""}</div>
                            </div>
                        </div> : null}

                    </div>
                    <div className=''>
                        {userType === USERTYPE.CLIENT ?
                            <Button disabled={getUserActionValue(tripData?.status).disabled} className='w-100 my-2 font-size-20' onClick={handleUserRideClick}>
                                {getUserActionValue(tripData?.status).text}
                            </Button>
                            : null}

                        {userType === USERTYPE.DRIVER ?
                            <Button className='w-100 my-2 font-size-20' onClick={handleDriverClick}>
                                {getDriverAction(tripData?.status).text}
                            </Button>
                            : null}
                    </div>

                </BottomSheetView>

            </div>
            {isThreeDotModalOpen ? (<BottomModal>
                <ClientMenuModal isOpen={isThreeDotModalOpen} setIsOpen={setIsThreeDotModalOpen} />
            </BottomModal >) : null}

            {cancelRide ? (<BottomModal>
                <CancelRideMenu isOpen={isThreeDotModalOpen} setIsOpen={setCancelRide} tripData={tripData} updateTripData={updateTripData} />
            </BottomModal>) : null}

            <CenteredModal visible={showDriverStartStopModal} onClose={handleDriverClick} title={isTripRunning(tripData?.status) ? 'Stop Trip' : 'Start Trip'} >
                <TripStartStop tripData={tripData} updateTripData={updateTripData} close={handleDriverClick} />
            </CenteredModal>
        </>
    )
}

export default TripTrackingView
