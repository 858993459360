import React, { useEffect, useRef, useState } from "react";
import { MapContainer, TileLayer, Marker, Polylines, Polyline, FeatureGroup, useMapEvents } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-markercluster";
import { CircleIcon, SquareIcon } from "../Map/Icons";
import L, { } from 'leaflet';
import { decodePolyline } from "../../helper/helper";
import { IoPinSharp } from "react-icons/io5";
import "./styles.css"

export default function OpenStreetMap({center, markers = [], polyLine = "", showLocationPicker = false, onMarkerDragged = () => { } }) {
    const mapRef = useRef(null);

    const [data, setData] = useState({
        markers: [],
        bounds: [],
        initialized: false,
        polyLinePath: [],
        center: [28.4594965, 77.0266383]//TODO use it from user location
    });

    const updateMapData = () => {
        try {
            if (!data.initialized) return;
            let updateData = null;

            //set markers data
            if (markers.length) {
                updateData = {
                    markers
                }
            }

            //set bounds using markers
            let bounds = markers.filter(marker => marker.lat && marker.lng).map((marker) => ([marker.lat, marker.lng]));

            //bounds using polyline
            if (polyLine) {
                let coordinates = decodePolyline(polyLine);
                if (coordinates.length) {
                    coordinates = coordinates.map(coord => ([coord.lat, coord.lng]));
                    updateData = {
                        ...updateData,
                        polyLinePath: coordinates
                    }
                    //update bounds
                    bounds = [...bounds, ...coordinates]
                }
            }

            if (data.initialized && mapRef.current && bounds.length) {
                //set bounds
                mapRef.current.fitBounds(bounds/* , { padding: [20, 20] } */);
                updateData = {
                    ...updateData,
                    bounds
                }
            }
            //update state data if any
            if (updateData) {
                setData(prev => ({ ...prev, ...updateData }));
            }
        } catch (error) {

        }
    }

    //update center
    useEffect(() => {
        if(center?.lat && center?.lng){
            setData(prev => ({ ...prev, center:[center?.lat,center?.lng] })) 
        }
    },[center])


    useEffect(() => {
        updateMapData();
    }, [data.initialized, markers, polyLine])

    const CenterMarker = () => {
        const map = useMapEvents({
            moveend: () => {
                const center = map.getCenter();
                setData(prev => ({ ...prev, center: [center.lat, center.lng] }));
                onMarkerDragged({ lat: center.lat, lng: center.lng })
            }
        });
        return null;
    };


    return (
        <>
            <MapContainer
                className="markercluster-map"
                center={[28.4594965, 77.0266383]}
                zoom={15}
                maxZoom={18}
                style={{ flex: 1 }}
                whenCreated={(map) => {
                    mapRef.current = map;
                    setData(prev => ({ ...prev, initialized: true }))
                }}
                bounds={data.bounds}
            >
                <TileLayer
                    // url="http://164.52.218.96:7020/tile/{z}/{x}/{y}.png"
                    url="https://map-tiles.zryd.in/tile/{z}/{x}/{y}.png"
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                />
                <MarkerClusterGroup>
                    {
                        data.markers.map((marker, index) => {
                            return (marker?.lat && marker?.lng ? <Marker key={index} position={[marker.lat, marker.lng]} icon={marker.icon} /> : null)
                        })
                    }
                </MarkerClusterGroup>
                {showLocationPicker ? <>
                    <CenterMarker />
                    <div className="map-fixed-pin-icon">
                        <IoPinSharp size={60} />
                    </div>
                </> : false}
                <Polyline positions={data.polyLinePath} color="red" />
            </MapContainer>
        </>
    );
}