import React from 'react'

const TakeOff = () => {
    return (
        <svg width="21" height="17" viewBox="0 0 21 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.4832 6.07048C20.6976 6.87068 20.2228 7.69318 19.4226 7.90758L3.75598 12.1054C3.31274 12.2242 2.84556 12.0261 2.62284 11.6249L0 6.90018L1.44889 6.51198L3.91668 8.95658L9.01198 7.59128L4.50146 0.51764L6.43331 0L13.3847 6.41958L18.6461 5.00981C19.4463 4.79539 20.2688 5.27027 20.4832 6.07048ZM2.53431 14.9587H18.5343V16.9587H2.53431V14.9587Z" fill="#3F3F3F" />
        </svg>
    )
}

export default TakeOff;