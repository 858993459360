import React from 'react';
import PlaneIcon from '../../../assets/svgs-icons/PlaneIcon';
import Hotel from '../../../assets/svgs-icons/Hotel';

const TrackingCardHeader = ({title,type,status}) => {
    return (<div className="d-flex p-2">
        <div className='d-flex align-items-center' style={{ flex: 1 }}>
                 {type==="flights"?<PlaneIcon />:<Hotel/>}
                 <div className='ms-2 urbanist-600'>{title}</div> 
        </div>
        <div className='d-flex justify-content-center align-items-center bg-light custom-padding rounded-3  urbanist-600' style={{ fontSize: 12,color:status?.color }}>{status?.title}</div>
        <hr />
    </div>)
}

export default TrackingCardHeader;