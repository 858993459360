import React, {
    useEffect,
    useState,
    useCallback,
    useRef,
    useMemo,
} from "react";
import { useLocation, useParams } from "react-router-dom";
import { doGET } from "../../utils/Httputil";
import { ENDPOINTS } from "../../LiveTracking/LiveTrackingConstant";
import { showMessage } from "../../utils/message";


export const UserContext = React.createContext();

export const UserProvider = (props) => {
    const [tourData, setTourData] = useState(null);
    const location = useLocation();
    const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search])
    const [error,setError] = useState(false);
    const [loading,setLoading] = useState(true);
    const [webContent, setWebContent] = useState(null)


    const fetchTourData = async (space_id, customer_id) => {
        try {
            setLoading(true)
            const response = await doGET(ENDPOINTS.tourDetail(space_id, customer_id))
            console.log(response)
            if(response?.data?.bearerToken){
                localStorage.setItem("bearerToken", response.data?.bearerToken)
            }
            setTourData(response.data)
        } catch (error) {  
            showMessage("error", "Invalid Tracking URL");
            setError(true) 
        }finally{
            setLoading(false)
        }
    }

    useEffect(() => {
        //for space based tracking
        if(location.pathname.includes("track")){
            const space_id = queryParams.get("s");
            const customer_id = queryParams.get("c");
            if (space_id && customer_id) {
                fetchTourData(space_id, customer_id)
            }else{
                setError(true);
            } 
        }
    }, [queryParams.toString(),location.pathname])

    function changeFavicon(src) {
        var link = document.querySelector("link[rel*='icon']") || document.createElement('link');
        link.type = 'image/x-icon';
        link.rel = 'shortcut icon';
        link.href = src;
        document.getElementsByTagName('head')[0].appendChild(link);
      }
    
      const getWebContent = async () => {
        try {
          const response = await doGET(ENDPOINTS?.getWebContent);
          if (response.status == 200) {
            document.title = response?.data?.name
            changeFavicon(response?.data?.favicon)
            setWebContent(response?.data)
          }
        } catch (error) {
    
        }
      }
    
    
      useEffect(() => {
        getWebContent()
      }, [])
    


    return (
        <UserContext.Provider
            value={{
                fetchTourData,
                tourData,
                setTourData,
                error,
                loading
            }}
        >
            {props?.children}
        </UserContext.Provider>
    );
};
