export const ApiHandler = async ({ endPoint, method, reqParam, token }) => {

    const acceptType = "application/json";
    const contentType = reqParam instanceof FormData ? "multipart/form-data" : "application/json";
    let response = null;
    try {
        let content = {
            method: method,
            headers: apiHeaders(reqParam, contentType, acceptType, token),
        };

        if (method === "POST" || method === "DELETE" || method === "PATCH" || method === "PUT") {
            content = { ...content, body: reqParam instanceof FormData ? reqParam : JSON.stringify(reqParam) };
        }

        if (reqParam instanceof FormData) {
            let headers = apiHeaders(reqParam, contentType, acceptType, token);
            response = await fetch(endPoint, { method, body: reqParam, headers });
        } else {
            response = await fetch(endPoint, content);
        }
    } catch (e) {
        console.log(e);
    }

    if (response) {
        const responseContentType = response.headers.get("Content-type");

        let data = null;

        if (responseContentType === null) return Promise.resolve(null);
        else if (responseContentType === "application/json")
            data = await response.json();
        else if (responseContentType.includes("text/plain"))
            data = await response.text();
        else if (responseContentType === "application/pdf") {
            data = await response.blob();
        } else if (responseContentType === "application/vnd.ms-excel") {
            // data = new Blob([await response], { type: responseContentType });
            data = new Blob([await response.blob()], { type: responseContentType });
        }
        response = {
            data: data,
            status: response?.status,
            statusText: response?.statusText,
            headers: response.headers,
        };
    }

    return response;

};

const apiHeaders = (reqParam, contentType, acceptType, token) => {
    // let myLang = localStorage.getItem("lang");
    const headers =
        reqParam instanceof FormData
            ? {
                Authorization: token,
                "x-api-key": "web",
                // "lang": myLang
            }
            : {
                Accept: acceptType,
                "x-api-key": "web",
                "Content-Type": contentType,
                Authorization: token,
                // "lang": myLang
            };
    return headers;
}

