import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';

const CustomImage = ({ src, style, ...restProps }) => {
    const [imageSrc, setImageSrc] = useState();

    const fetchImage = async (src) => {
        if (src) {
            try {
                const response = await axios({
                    url: src,
                    headers: {
                        Authorization: localStorage.getItem("BearerToken"),
                    },
                    withCredentials: true,
                    responseType: "blob",
                });
                const reader = new FileReader();
                reader.onload = () => {
                    setImageSrc(reader.result);
                };
                reader.readAsDataURL(response?.data);
            } catch (error) {
                console.error("Error fetching image:", error);
            }
        }
    };

    useEffect(() => {
        if (src && (src.slice(1, 4) === "api")) {
            fetchImage(src);
        }
    }, [src]);


    return (
        <div>
            {src && imageSrc ?
                <img
                    key={src}
                    src={imageSrc}
                    alt="Image"
                    style={style}
                    {...restProps}
                />
                : <i className='bx font-size-20 bx-image-alt' />}
        </div>
    );
};

export default CustomImage;
