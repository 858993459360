import React from 'react'

const PlaneIcon = () => {
    return (
        <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.66667 6.45549L16.3333 10.666V12.3327L9.66667 10.2274V14.6938L12.1667 16.0827V17.3327L8.41667 16.4993L4.66667 17.3327V16.0827L7.16667 14.6938V10.2274L0.5 12.3327V10.666L7.16667 6.45549V1.91602C7.16667 1.22566 7.72633 0.666016 8.41667 0.666016C9.107 0.666016 9.66667 1.22566 9.66667 1.91602V6.45549Z" fill="#3F3F3F" />
        </svg>
    )
}

export default PlaneIcon
