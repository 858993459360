import React, { useState, useEffect } from 'react'
import { IoArrowBackOutline } from 'react-icons/io5'
import { Button, Card } from 'reactstrap'
import { PiPhoneCallFill } from "react-icons/pi";
import { MdAddCall } from "react-icons/md";
import { RxCross2 } from "react-icons/rx";
import './style.scss'

const CallOptions = ({ setIsOpen, data }) => {
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const checkIfMobile = () => {
            return /Mobi|Android/i.test(navigator.userAgent);
        };

        setIsMobile(checkIfMobile());
    }, []);
    return (
        <Card className='w-100 shadow'>
            <div className='d-flex justify-content-between px-3 py-2'>
                <div className='font-size-24'>Contact {data?.driver?.name} </div>
                <div></div>
                <div className='cursor-style' onClick={() => {
                    setIsOpen(false)
                }}><RxCross2 size={28} /></div>
            </div>

            {isMobile ? (
                <a href='tel:+919876543210' className='my-2 mx-3'>
                    <Button className='font-size-20 w-100'>
                        {data?.driver?.phone?.countryCode ? "+" + `+${data?.driver?.phone?.countryCode}` : ""} {data?.driver?.phone?.phone ?? ""}
                    </Button>
                </a>
            ) : (
                <Button className='my-2 mx-3 font-size-20'>
                    {data?.driver?.phone?.countryCode ? "+" + data?.driver?.phone?.countryCode : ""} {data?.driver?.phone?.phone ?? ""}
                </Button>
            )}

            <Button className='my-2 mx-3 font-size-20'>
                Free Call
            </Button>
        </Card>
    )
}

export default CallOptions