import React from 'react'

const Heading = ({ title }) => {
    return (
        <div className="p-2 urbanist-600" style={{ borderBottom: '1px solid #C4C4C4' }}>
            <div>{title}</div>
        </div>
    )
}

export default Heading;