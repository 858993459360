import React from 'react'
import { Spinner } from 'reactstrap'

function Loader() {
  return (
    <div className='vh-100 w-100 d-flex justify-content-center align-items-center'>
        <Spinner />
    </div>
  )
}

export default Loader