import React, { useEffect, useState } from 'react'
import OTPInput from '../OTPComponent/OTPInput'
import { Button } from 'reactstrap';
import { isTripReadyToStart, isTripRunning } from '../../utils/config';
import { ENDPOINTS } from '../../LiveTracking/LiveTrackingConstant';
import { doPOST } from '../../utils/Httputil';
import CenteredModal from '../CenteredModal';
import SignPad from '../SignPad';
import { showMessage } from '../../utils/message';
import LocationInfo from '../LocationInfo';

function showError(error) {
    switch (error.code) {
        case error.PERMISSION_DENIED:
            alert("User denied the request for Geolocation.")
            break;
        case error.POSITION_UNAVAILABLE:
            alert("Location information is unavailable.")
            break;
        case error.TIMEOUT:
            alert("The request to get user location timed out.")
            break;
        case error.UNKNOWN_ERROR:
            alert("An unknown error occurred.")
            break;
    }
}

function TripStartStop({ tripData, updateTripData, close }) {
    // handleOTPChange, value, placeholder 
    const [data, setData] = useState({
        otp: "",
        km: "",
        endKM: "",
        stopOTP: "",
        lat: 28.419399057968807/* location?.coords?.latitude */,
        lng: 77.03801189069809/* location?.coords?.longitude */,
    })

    const [modals, setModals] = useState({
        showSignPad: false,
        showLocationInfo: false
    })

    const toggleModal = (type) => setModals(prev => ({ ...prev, [type]: !prev[type] }))

    const handleValueChange = (e) => {
        setData(prevData => ({ ...prevData, [e.target.id]: e.target.value }))
    }

    const getLocation = () => {
        return new Promise((resolve, reject) => {
            try {
                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition((position) => {
                        console.log(position)
                        return resolve(position);
                    }, (error) => {
                        // Location permission denied or location services disabled
                        console.error('Error getting location:', error.message);
                        const userAgent = navigator.userAgent || navigator.vendor || window.opera;
                        const isMobile = (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent));
                        const message = isMobile ? "Please enable location services in your device settings." : "Please enable location services to use this feature";
                        showMessage('error', message);
                        reject({ message, locationError: true });
                    });
                } else {
                    reject({ message: "Please enable location services to use this feature", locationError: true });
                }
            } catch (error) {
                reject({ message: "Please enable location services to use this feature", locationError: true });
            }
        })
    }

    const handleStartTrip = async () => {
        try {
            const reqParam = {
                kms: data.km,
                lat: 28.419399057968807/* location?.coords?.latitude */,
                lng: 77.03801189069809/* location?.coords?.longitude */,
                otp: data.otp,
                trip_id: tripData?._id
            }
            if (window.location.protocol === 'https:') {
                const location = await getLocation();
                reqParam.lat = location?.coords?.latitude;
                reqParam.lng = location?.coords?.longitude
            }

            const response = await doPOST(ENDPOINTS.startTrip, reqParam);
            if (response.status == 200) {
                //trip started update trip detail
                updateTripData(response.data);
                close();
                showMessage("success", "Trip started successfully");
                return;
            }

        } catch (error) {
            if (error.locationError) return;//as location error already handled above
            console.log(error);
            let message = "Something went wrong"
            if (error?.message) {
                message = JSON.parse(error.message).message
            }
            showMessage("error", message);
        }
    }
    const handleStopTrip = async (sign) => {
        try {
            const reqParam = {
                kms: data.endKM,
                lat: 28.419399057968807/* location?.coords?.latitude */,
                lng: 77.03801189069809/* location?.coords?.longitude */,
                trip_id: tripData?._id,
                sign
            }
            if (window.location.protocol === 'https:') {
                const location = await getLocation();
                reqParam.lat = location?.coords?.latitude;
                reqParam.lng = location?.coords?.longitude
            }

            const response = await doPOST(ENDPOINTS.stopTrip, reqParam);
            console.log(response);

            if (response.status == 200) {
                //trip started update trip detail
                updateTripData(response.data);
                close();
                if (modals.showSignPad) {
                    toggleModal('showSignPad');
                }
                showMessage("success", "Trip stopped successfully");
                return;
            }

        } catch (error) {
            if (error.locationError) return;//as location error already handled above
            console.log(error);
            let message = "Something went wrong"
            if (error?.message) {
                message = JSON.parse(error.message)?.message
            }
            showMessage("error", message);
        }
    }

    useEffect(() => {
        const locationInfoShown = localStorage.getItem('LOCATION_INFO_SHOWN');
        if (!locationInfoShown) {
            toggleModal('showLocationInfo');
        }
    }, [])

    const handleLocationInfoClose = (accepted = false) => {
        if (!accepted) close();
        else {
            getLocation().then(location => {
                setData((prev) => ({ ...prev, lat: location?.coords?.latitude, lng: location?.coords?.longitude }));
                toggleModal('showLocationInfo');
                localStorage.setItem('LOCATION_INFO_SHOWN', 'true')
            })
                .catch(error => {
                    console.log(error);
                    close();
                })


        }
    }

    return (
        <>
            <div className='d-flex flex-column'>
                {!modals.showLocationInfo ? <>
                    {tripData?.tripExtn?.otpNotRequired ? null : <>
                        <p className='p-0 m-0'>Enter Start OTP</p>
                        <OTPInput
                            autoFocus
                            length={4}
                            className="otpContainer"
                            inputClassName="otpInput"
                            // onChangeOTP={(otp) => console.log("String OTP: ", otp)}
                            onChangeOTP={(otp) => setData(prevData => ({ ...prevData, otp }))}
                        />
                    </>}

                    {/* {isTripReadyToStart(tripData?.status) ?
                    <input type="number" id='otp' className='mb-2 rounded-2 border-1 py-2 px-2' placeholder='Enter Start OTP' onChange={handleValueChange} maxLength={4} />
                    : null} */}

                    {isTripReadyToStart(tripData?.status) ?
                        <input type="number" id='km' className='mb-2 rounded-2 border-1 border-opacity-25 py-2 px-2' placeholder='Enter Start KM' onChange={handleValueChange} maxLength={12} />
                        : null}

                    {isTripRunning(tripData?.status) ?
                        <input type="number" id='endKM' className='mb-2 rounded-2 border-1 border-opacity-25 py-2 px-2' placeholder='Enter End KM' onChange={handleValueChange} maxLength={12} />
                        : null}

                    {isTripReadyToStart(tripData?.status) ? <Button disabled={!data.km || (!tripData?.tripExtn?.otpNotRequired && (!data.otp || data.otp.length !== 4))} className='w-100 my-2 font-size-20' onClick={handleStartTrip}>
                        Start Trip
                    </Button> : null}
                    {isTripRunning(tripData?.status) ? <Button disabled={!(data.endKM)} className='w-100 my-2 font-size-20' onClick={() => toggleModal('showSignPad')}>
                        Stop Trip
                    </Button> : null}

                    <CenteredModal visible={modals.showSignPad} onClose={() => toggleModal('showSignPad')} title={'Submit Pax Signature'} background={'#ddd'} >
                        <SignPad totalDistance={Math.abs(data?.endKM - tripData?.tripExtn?.startKm ?? 0)} handleSubmit={(sign) => handleStopTrip(sign)} onClose={() => toggleModal('showSignPad')} />
                    </CenteredModal>
                </> :
                    <LocationInfo close={handleLocationInfoClose} />}
            </div>
        </>
    )
}

export default TripStartStop