import React, { useEffect, useRef } from 'react'
import SignatureCanvas from 'react-signature-canvas'
import { Button } from 'reactstrap';
import { showMessage } from '../../utils/message';


function SignPad({ style = {}, handleSubmit = () => { }, totalDistance = 0 }) {
    let sigCanvas = useRef();

    const clear = () => {
        sigCanvas.current?.clear();
    }

    return (
        <>
            <p className='text-center fs-6 fw-medium'>Total Distance travelled: {totalDistance} kms</p>
            <div className='bg-white rounded-2 mb-2'>
                <SignatureCanvas
                    penColor='black'
                    ref={sigCanvas}
                    canvasProps={{ width: '400px', height: '500px', className: 'sigCanvas', backgroundColor: '#FFF', ...style }}

                />

            </div>
            <div className='d-flex gap-2'>
                <Button outline className='w-100 my-2 font-size-20' onClick={clear}>
                    Clear
                </Button>
                <Button /* disabled={sigCanvas.current.isEmpty()} */ className='w-100 my-2 font-size-20' onClick={() => {
                    if (sigCanvas.current.isEmpty()) {
                        showMessage('error', 'Please submit Passenger Signature.')
                        return;
                    }
                    handleSubmit(sigCanvas.current?.getTrimmedCanvas().toDataURL('image/png'))
                }}>
                    Submit
                </Button>
            </div>
        </>

    )
}

export default SignPad