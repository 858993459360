import React from 'react'

const Landing = () => {
    return (
        <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.2449 12.7998C18.0305 13.6 17.208 14.0749 16.4078 13.8605L0.741183 9.66256C0.297953 9.54386 -0.00756734 9.13866 0.000142662 8.67986L0.0910426 3.27671L1.53993 3.66494L2.45481 7.01591L7.5501 8.38116L7.18069 0L9.1125 0.51764L11.9228 9.55286L17.1842 10.9627C17.9844 11.1771 18.4593 11.9996 18.2449 12.7998ZM1.29598 16.9116H17.296V18.9116H1.29598V16.9116Z" fill="#3F3F3F" />
        </svg>
    )
}

export default Landing;