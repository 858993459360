import React, { useEffect, useRef, useState } from 'react';
import "./style.scss"

function BottomSheetView({ children, className = "" }) {
    const divRef = useRef(null);
    const [isDragging, setIsDragging] = useState(false);
    const [startY, setStartY] = useState(0);
    const [startHeight, setStartHeight] = useState(0);

    const handleDragStart = (e) => {
        e.preventDefault();
        setIsDragging(true);
        const clientY = e.type === 'touchstart' ? e.touches[0].clientY : e.clientY;
        setStartY(clientY);
        setStartHeight(divRef.current.clientHeight);
    };

    const handleDragging = (e) => {
        e.preventDefault();
        if (!isDragging) return;

        const clientY = e.type === 'touchmove' ? e.touches[0].clientY : e.clientY;
        const deltaY = startY - clientY;
        const newHeight = startHeight + deltaY;

        const minHeight = 25; //  vh
        const maxHeight = 75; //   vh
        const clampedHeight = Math.min(Math.max(newHeight, minHeight * window.innerHeight / 100), maxHeight * window.innerHeight / 100);

        divRef.current.style.height = `${clampedHeight}px`;
    };

    const handleDragStop = () => {
        setIsDragging(false);
    };

    useEffect(() => {
        document.addEventListener('mousemove', handleDragging);
        document.addEventListener('mouseup', handleDragStop);
        document.addEventListener('touchmove', handleDragging, { passive: false });
        document.addEventListener('touchend', handleDragStop);

        return () => {
            document.removeEventListener('mousemove', handleDragging);
            document.removeEventListener('mouseup', handleDragStop);
            document.removeEventListener('touchmove', handleDragging);
            document.removeEventListener('touchend', handleDragStop);
        };
    }, [isDragging]);

    return (
        <div
            ref={divRef}
            className={`bottom-sheet cursor-pointer cursor-style bg-white px-2 rounded-top-4 border ${className}`}
            style={{ height: '25vh', overflowY: 'auto', position: 'absolute', bottom: 0, left: 0, right: 0, zIndex: 99999 }}
            onTouchStart={handleDragStart}
            onMouseDown={handleDragStart}
        >
            <div className=' mx-auto bg-secondary rounded-1 my-2 scroller ' />
            {children}
        </div>
    );
}

export default BottomSheetView;
