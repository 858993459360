import React from 'react'

const Calender = () => {
    return (
        <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.79167 0V1.08333H7.04167V0H8.125V1.08333H10.2917C10.5908 1.08333 10.8333 1.32585 10.8333 1.625V10.2917C10.8333 10.5908 10.5908 10.8333 10.2917 10.8333H0.541667C0.242515 10.8333 0 10.5908 0 10.2917V1.625C0 1.32585 0.242515 1.08333 0.541667 1.08333H2.70833V0H3.79167ZM9.75 5.41667H1.08333V9.75H9.75V5.41667ZM2.70833 2.16667H1.08333V4.33333H9.75V2.16667H8.125V3.25H7.04167V2.16667H3.79167V3.25H2.70833V2.16667Z" fill="#818181" />
        </svg>
    )
}

export default Calender;