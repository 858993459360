import React from 'react'
import { IoArrowBackOutline } from 'react-icons/io5'
import { RxCross2 } from 'react-icons/rx'
import { Button, Card } from 'reactstrap'
import CallAdmin from '../Call/CallAdmin'

const ClientMenuModal = ({ setIsOpen }) => {
    return (
        <Card className='w-100 shadow '>
            <CallAdmin setIsOpen={setIsOpen} />
        </Card>
    )
}

export default ClientMenuModal