import { makeAutoObservable } from "mobx";
import { doGET, doPOST } from "../utils/Httputil";
import { ENDPOINTS } from "./LiveTrackingConstant";
import { decodePolyline } from "../helper/helper";
class Service {

    center = {};
    markers = [];
    path = [];
    tripId = null;
    socketTripId = null; // for disconnecting socket leave room
    polyLine = ""

    constructor() {
        makeAutoObservable(this);
    }


    setTripId = function (value) {
        this.tripId = value;
    }

    setMarkers = function (marker) {
        this.markers = marker;
    }

    setPath = function (path) {
        this.path = path;
    }

    setCenter = function (center) {
        this.center = center;
    }

    setSocketTripId = function (value) {
        this.socketTripId = value;
    }

    getVehicleType = async (id) => {
        const response = await doGET(ENDPOINTS.getVehicleType(id));

        if (response.status === 200) {
            return response.data ? response.data : response;
        }
    };

    getTrip = async (id) => {
        const response = await doGET(ENDPOINTS.getTrip(id));

        if (response.status === 200) {
            return response.data ? response.data : response;
        }
    };

    locateVehicle = async (data) => {
        const response = await doPOST(ENDPOINTS.movements, data);
        if (response.status === 200) {
            return response.data ? response.data : response;
        }
    }

    fetchVehicleData = async (trip) => {
        try {
            let vehicleData = null
            if (trip?.vehicle?.makeModel_id) {
                let response = await doGET(ENDPOINTS.makeModel(trip?.vehicle?.makeModel_id));
                const { make = "", model = "" } = response?.data ?? {};
                vehicleData = {
                    ...(response?.data ?? {}),
                    name: `${make} ${model}`
                }
            } else if (trip?.tripExtn?.requestedVehicleType || trip?.tripExtn?.requestedMakeModel) {
                let request = trip?.tripExtn?.requestedVehicleType ?
                    doGET(ENDPOINTS.vehicleDetail(trip?.tripExtn?.requestedVehicleType)) :
                    (trip?.tripExtn?.requestedMakeModel ? doGET(ENDPOINTS.makeModel(trip?.tripExtn?.requestedMakeModel)) : null)

                vehicleData = request ? await request : null
            }
            return vehicleData;

        } catch (error) {
            console.log(error);
        }
    }

    fetchPath = async (srcLat,srcLng,dstLat,dstLng) => {
        try {
            const response = await doGET(`/api/geocode/routes?src=${srcLat},${srcLng}&dst=${dstLat},${dstLng}`)
            if (response.status === 200 && response.data?.paths[0]?.polyline) {
                return response.data?.paths[0]?.polyline  
            }
            return null
        } catch (error) {
            console.log(error)
        }
    }
}

const LiveTrackingService = new Service();
export default LiveTrackingService;

