import React from 'react'

const Layout = ({ children }) => {
    return (
        <div className='pb-2' style={{ maxWidth: "450px", margin: "auto",position:'relative',minHeight:'100vh' }}>
            {children}
        </div>
    )
}

export default Layout
