import React from 'react'
import { FaCircle, FaSquare } from 'react-icons/fa'
import { IoNavigate } from "react-icons/io5";

const AddressDisplayComponent = ({ tripData }) => {
    const handleNavigation = (latitude, longitude) => {
        window.open(`https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`);
    };
    return (
        <div className='px-3 py-2 my-2 shadow-md border-radius-12'>
            <div className='d-flex align-items-start justify-content-between text-color-grey mb-2'>
                <div className='d-flex align-items-start'><div className='leftIconWidth'><FaCircle size={14} className='mt-1' /></div>
                    <div className='ms-1 font-size-18 two-line-ellipsis'>
                        {tripData?.src?.addressLine ?? ""}   {tripData?.src?.city ?? ""}   {tripData?.src?.state ?? ""}   {tripData?.src?.country ?? ""}
                    </div></div>
                <div className=' bg-light p-1 rounded-1 ms-1 cursor-style' onClick={() => handleNavigation(tripData?.src?.lat, tripData?.src?.lng)}>
                    <IoNavigate size={20} />
                </div>
            </div>
            <div className='flex-1 verticle-dotted-line'></div>
            {tripData?.dst ? <div className='d-flex align-items-start justify-content-between text-color-grey'>
                <div className='d-flex align-items-start'>
                    <div className='leftIconWidth'><FaSquare size={14} className='mt-1' /></div>
                    <div className='ms-2 font-size-18 two-line-ellipsis'>
                        {tripData?.dst?.addressLine ?? ""}   {tripData?.dst?.city ?? ""}   {tripData?.dst?.state ?? ""}   {tripData?.dst?.country ?? ""}
                    </div>
                </div>
                <div className=' bg-light p-1 rounded-1 ms-1 cursor-style' onClick={() => handleNavigation(tripData?.dst?.lat, tripData?.dst?.lng)}>
                    <IoNavigate size={20} />
                </div>
            </div> : null}

        </div>
    )
}

export default AddressDisplayComponent