export const BASE_URL = "https://app-zoyride.logicfirst.io"

export const USERTYPE = {
    CLIENT: 'client',
    DRIVER: 'driver'
}


export const TRIP_STATUS = {
    CREATED: 0,
    CONFIRMED: 1,
    READY: 2,
    DISPATCHED: 3,
    LIVE: 4,
    COMPLETED: 5,
    CANCELLED: 6,
    ERROR: 7,
    PAUSED: 8,
    EXPIRED: 9,
}

export const isTripRunning = (status) => [TRIP_STATUS.LIVE, TRIP_STATUS.DISPATCHED].includes(status);
export const isTripEnded = (status) => [TRIP_STATUS.COMPLETED, TRIP_STATUS.CANCELLED, TRIP_STATUS.EXPIRED].includes(status);
export const isTripReadyToStart = (status) => [TRIP_STATUS.CREATED, TRIP_STATUS.CONFIRMED, TRIP_STATUS.READY].includes(status);
 
export const getFlightStatus=(status)=>{
    switch (status) {
        case 1:
            return {title:"Booked",color: "#4CAF50"  }
        case 2:
            return {title:"Cancelled",color: "#F44336"}
        case 3:
            return{ title:"Delayed",color:"orange"}
    
        default:
            return {title:'Pending',olor: "#FF9800"};
    }
}  

export const getStayStatus = (status) => {
    switch (status) {
        case 1:
            return { title: "Booked", color: "#4CAF50" };  
        case 2:
            return { title: "CheckedIn", color: "#FF9800" };  
        case 3:
            return { title: "CheckedOut", color: "#2196F3" };  
        default:
            return { title: "Cancelled", color: "#F44336" };  
    }
};

 