import moment from 'moment';
import React from 'react';

const TimeLineDetails = ({city,time,date}) => {
    return (
        <div>
            <p className='p-0 m-0 fs-6 gray-color urbanist-600'>{city}</p>
            <p className='p-0 m-0 fs-5 text-dark urbanist-600'>{date}</p>
            {time ? moment(time.length === 3 ? `0${time}` : time, 'Hmm').format('HH:mm') : '-'}
        </div>
    )
}

const TimeLine = ({
    fromCity,
    toCity,
    fromDate,
    toDate,
    fromTime,
    toTime
}) => {
    return (
        <div className='d-flex p-1 pt-3 mt-2' style={{ borderTop: '1px solid #C4C4C4' }}>
            <TimeLineDetails 
                city={fromCity} 
                date={fromDate?moment(fromDate,'YYYYMMDD').format('DD MMM YY'):'-'} 
                time={fromTime}
                />
            <div style={{ flex: 1, padding: '0 10px' }}>
                <div className="d-flex justify-content-center align-items-center position-relative timeline-container">
                    <div className="circle bg-white position-absolute start-0 border border-dark"></div>
                    <div className="line bg-dark flex-grow-1"></div>
                    <div className="circle bg-white position-absolute end-0 border border-dark"></div>
                    <div className="text-container text-muted urbanist-500 position-absolute bottom-0 start-50 translate-middle pb-1">
                         {}
                    </div>
                </div>
            </div>
            <TimeLineDetails 
             city={toCity} 
             time={toTime}
             date={toDate?moment(toDate,'YYYYMMDD').format('DD MMM YY'):'-'} 
            />
        </div>
    )
}


export default TimeLine;