import React from 'react'

const Pnr = () => {
    return (
        <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 0.899999C0 0.402939 0.402948 0 0.899999 0H17.1C17.5971 0 18 0.402939 18 0.899999V5.84999C16.7574 5.84999 15.75 6.85737 15.75 8.1C15.75 9.34263 16.7574 10.35 18 10.35V15.3C18 15.7971 17.5971 16.2 17.1 16.2H0.899999C0.402948 16.2 0 15.7971 0 15.3V0.899999ZM5.47682 14.4C5.66214 13.8757 6.1622 13.5 6.74999 13.5C7.3378 13.5 7.83784 13.8757 8.02315 14.4H16.2V11.729C14.8665 11.0663 13.95 9.69012 13.95 8.1C13.95 6.50979 14.8665 5.13369 16.2 4.47098V1.8H8.02315C7.83784 2.32433 7.3378 2.7 6.74999 2.7C6.1622 2.7 5.66214 2.32433 5.47682 1.8H1.8V14.4H5.47682ZM6.74999 7.2C6.00442 7.2 5.39999 6.59556 5.39999 5.84999C5.39999 5.10441 6.00442 4.5 6.74999 4.5C7.49557 4.5 8.10001 5.10441 8.10001 5.84999C8.10001 6.59556 7.49557 7.2 6.74999 7.2ZM6.74999 11.7C6.00442 11.7 5.39999 11.0956 5.39999 10.35C5.39999 9.60444 6.00442 9 6.74999 9C7.49557 9 8.10001 9.60444 8.10001 10.35C8.10001 11.0956 7.49557 11.7 6.74999 11.7Z" fill="#3F3F3F" />
        </svg>
    )
}

export default Pnr;