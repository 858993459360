

import L, { Icon } from 'leaflet';

import circle from "../../assets/map/circle.png"
import square from "../../assets/map/square.png"
import car from "../../assets/images/car-top.png"

export const CircleIcon = new Icon({
    iconUrl: circle,
    iconSize: [20, 20],
    iconAnchor: [16, 16],
})

export const SquareIcon = new Icon({
    iconUrl: square,
    iconSize: [20, 20],
    iconAnchor: [16, 16],
})
export const CarIcon = new Icon({
    iconUrl: car,
    iconSize: [40, 40],
    iconAnchor: [16, 16],
})