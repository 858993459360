import * as React from "react";
const Hotel = (props) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.167 15.8333H15.8337V9.16667H10.8337V15.8333H12.5003V10.8333H14.167V15.8333ZM2.50033 15.8333V3.33333C2.50033 2.8731 2.87343 2.5 3.33366 2.5H15.0003C15.4606 2.5 15.8337 2.8731 15.8337 3.33333V7.5H17.5003V15.8333H18.3337V17.5H1.66699V15.8333H2.50033ZM5.83366 9.16667V10.8333H7.50033V9.16667H5.83366ZM5.83366 12.5V14.1667H7.50033V12.5H5.83366ZM5.83366 5.83333V7.5H7.50033V5.83333H5.83366Z"
      fill="#3F3F3F"
    />
  </svg>
);
export default Hotel;
