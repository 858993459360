import { makeAutoObservable } from "mobx";
 
 
class Service {
   location=null;
   error=null;

    constructor() {
        makeAutoObservable(this);
    }

    getLocation = () => {
        return new Promise((resolve, reject) => {
            try {
                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition((position) => {
                        console.log(position)
                        return resolve(position);
                    }, (error) => {
                        // Location permission denied or location services disabled
                        console.error('Error getting location:', error.message);
                        const userAgent = navigator.userAgent || navigator.vendor || window.opera;
                        const isMobile = (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent));
                        const message = isMobile ? "Please enable location services in your device settings." : "Please enable location services to use this feature";
                        // showMessage('error', message);
                        reject({ message, locationError: true });
                    });
                } else {
                    reject({ message: "Please enable location services to use this feature", locationError: true });
                }
            } catch (error) {
                reject({ message: "Please enable location services to use this feature", locationError: true });
            }
        })
    }


    fetchLocation=()=>{
        return new Promise(async(resolve,reject)=>{
            this.getLocation().then(location => {
                this.location={
                    lat: location?.coords?.latitude,
                    lng: location?.coords?.longitude
                }
                return resolve(this.location)
            })
                .catch(error => {
                    console.log(error); 
                    this.error=error;
                    return reject(this.error)
                })  
            
        })

    }

}

const LocationService = new Service();
export default LocationService;

