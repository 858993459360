import React from 'react'
import MapPointer from "../../../assets/svgs-icons/MapPointer"
import Calender from "../../../assets/svgs-icons/Calender"
import moment from 'moment'

const DetailHotel = ({
    bookingDate,
    checkInDate,
    checkOutDate,
    checkInTime,
    checkOutTime,
    address="",
    contact=""
}) => {
    return (
        <>
            {address?<div className='d-flex align-items-start px-2'>
                {/* <MapPointer size={20} className='mt-2'/> */}
                <div className='p-0 m-0 ps-2 text-muted urbanist-600  break-word'>{address}</div>
            </div>:null}
            <div className='d-flex align-items-center px-2'>
                <Calender />
                <p className='p-0 m-0 ps-2 text-muted'>{moment(checkInDate, 'YYYYMMDD').format("DD MMMM YYYY")} - {moment(checkOutDate, 'YYYYMMDD').format("DD MMMM YYYY")}</p>
            </div>
        </>
    ) 
}

export default DetailHotel
