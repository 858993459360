import React from 'react'
import CustomImage from '../CustomImage'

function VehicleInfo({ tripData = {} }) {
    return (
        <>
            {tripData?.vehicleData ? <div className='px-3 my-2 py-2 d-flex justify-content-between shadow-md border-radius-12 bg-white' >
                <div className='d-flex flex-column align-items-center'>
                    {/* <div><img className='vehicle-img-style' src={`https://app.zoyride.com/api/o/assets/vehicle-type/65a53b7683edbb5517894a00/277b3a54-d29a-4544-a004-c45eb259753b/medium-suv.png`} /></div> */}
                    <CustomImage src={tripData?.vehicleData?.pic} style={styles.vehicleImage} />
                    <div className='font-size-16 fw-bold'>{tripData?.vehicleData?.name}</div>
                </div>
                <div>
                    <div className='font-size-18'>{tripData?.vehicle?.regNo}</div>
                </div>
            </div> : null}
        </>
    )
}

export default VehicleInfo

const styles = {
    vehicleImage: {
        height: '50px',
        width: 'auto'
    }
}