import './App.css';
import React, { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import './style.scss'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Tracking from './LiveTracking/Tracking';
import LandingScreen from './pages/LandingScreen';
import AddressSelect from './pages/AddressSelect';


function App() {
  return (
    <>
      <Suspense >
        <Routes>
          <Route>
            <Route path="/" element={<Tracking />} />
            <Route path="/track" element={<LandingScreen />} />
            <Route path="/:trkId" element={<Tracking />} />
            <Route path="/set-home" element={<AddressSelect />} />
          </Route>
        </Routes>
      </Suspense>
      <ToastContainer position="top-right"
        autoClose={4000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
}

export default App;