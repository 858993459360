import { toast } from "react-toastify";

export const showMessage = (type, message) => {
    if (type === 'success') {
        return toast.success(message);
    } else if (type === 'error') {
        return toast.error(message);
    } else if (type === 'warning') {
        return toast.warning(message);
    } else if (type === 'info') {
        return toast.info(message);
    }
    else {
        return toast(message);
    }

}
