import moment from "moment";

function decodePolyline(encodedString) {
    if (!encodedString) {
        return null;
    }

    let time = null;
    if (encodedString.startsWith("AAA")) {
        time = parseInt(encodedString.substring(3, encodedString.indexOf("===")));
        encodedString = encodedString.substring(encodedString.indexOf("===") + 3);
    }

    let hasSpeed = false;
    if (encodedString.startsWith("AAB")) {
        time = parseInt(encodedString.substring(3, encodedString.indexOf("===")));
        encodedString = encodedString.substring(encodedString.indexOf("===") + 3);
        hasSpeed = true;
    }

    let index = 0;
    const len = encodedString.length;
    let lat = 0,
        lng = 0;
    let tym = 0,
        speed = 0;
    const polyline = [];

    while (index < len) {
        let b, shift, result;
        shift = result = 0;
        do {
            b = encodedString.charCodeAt(index++) - 63;
            result |= (b & 0x1f) << shift;
            shift += 5;
        } while (b >= 0x20);
        const dlat = (result & 1) !== 0 ? ~(result >> 1) : result >> 1;
        lat += dlat;

        shift = result = 0;
        do {
            b = encodedString.charCodeAt(index++) - 63;
            result |= (b & 0x1f) << shift;
            shift += 5;
        } while (b >= 0x20);
        const dlng = (result & 1) !== 0 ? ~(result >> 1) : result >> 1;
        lng += dlng;

        if (time !== null) {
            shift = result = 0;
            do {
                b = encodedString.charCodeAt(index++) - 63;
                result |= (b & 0x1f) << shift;
                shift += 5;
            } while (b >= 0x20);
            const dtym = (result & 1) !== 0 ? ~(result >> 1) : result >> 1;
            tym = dtym;
        }

        if (hasSpeed) {
            shift = result = 0;
            do {
                b = encodedString.charCodeAt(index++) - 63;
                result |= (b & 0x1f) << shift;
                shift += 5;
            } while (b >= 0x20);
            const dspeed = (result & 1) !== 0 ? ~(result >> 1) : result >> 1;
            speed = dspeed;
        }

        const p = {
            lat: lat / 100000,
            lng: lng / 100000,
        };

        if (time !== null) {
            p.time = time + tym;
        }

        if (speed !== -1) {
            p.speed = speed;
        }

        polyline.push(p);
    }

    return polyline;
}

const getTripType = (tripType) => {
    const tripTypeMapping = {
        1: 'Local',
        2: 'Shared',
        3: 'Self Drive',
        4: 'Route',
        5: 'Airport Pick',
        6: 'Airport Drop',
        7: 'Tour Package',
        8: 'Outstation One Way',
        9: 'Outstation Round Trip',
    };

    return tripTypeMapping[tripType] || '';
};

function calculateDuration(startDateString, startTimeString, endDateString, endTimeString) {
    if (!startDateString || !startTimeString || !endDateString || !endTimeString) return "";

    // Ensure time strings are in HHmm format
    const formattedStartTime = startTimeString.length === 3 ? `0${startTimeString}` : startTimeString;
    const formattedEndTime = endTimeString.length === 3 ? `0${endTimeString}` : endTimeString;

    const startDateTime = moment(startDateString + ' ' + formattedStartTime, "YYYYMMDD Hmm");
    const endDateTime = moment(endDateString + ' ' + formattedEndTime, "YYYYMMDD Hmm");

    // Check if the dates are valid
    if (!startDateTime.isValid() || !endDateTime.isValid()) {
        return "-";
    }

    const duration = moment.duration(endDateTime.diff(startDateTime));
    const parts = [];
    if (duration.days() !== 0) parts.push(`${duration.days()} days`);
    if (duration.hours() !== 0) parts.push(`${duration.hours()} hours`);
    if (duration.minutes() !== 0) parts.push(`${duration.minutes()} minutes`);
    if (duration.seconds() !== 0) parts.push(`${duration.seconds()} seconds`);

    return parts.join(', ');
}

export { decodePolyline, getTripType,calculateDuration }