import React from 'react'

const ArrowLeft = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.828 6.97146H16V9.02854H3.828L9.192 14.5456L7.778 16L0 8L7.778 0L9.192 1.45436L3.828 6.97146Z" fill="black" />
        </svg>
    )
}

export default ArrowLeft